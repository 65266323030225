'use client';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { Modal, type ModalProps } from './Modal';

export function ModalWithX({ children, ...props }: ModalProps) {
  return (
    <Modal {...props}>
      <div className="relative">
        <div className="absolute right-0 top-0 cursor-pointer">
          <button className="relative -right-1.5 -top-1.5 p-1.5" onClick={props.closeModal}>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div>{children}</div>
      </div>
    </Modal>
  );
}
