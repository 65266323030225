import { create } from 'zustand';
import { listenUserFiles } from '../API/files.requests.ts';
import { File, FileId } from '../domain/files/File.ts';

type FileStore = FileStoreState & FileStoreActions;

interface FileStoreState {
  userId: string | null;
  files: File[] | null;
  listening: boolean;
  unsubscribe: (() => void) | null;
}

interface FileStoreActions {
  listenFiles: (userId: string) => void;
}

export const useFilesStore = create<FileStore>((set, get) => ({
  userId: null,
  files: null,
  listening: false,
  listenersCount: 0,
  unsubscribe: null,

  listenFiles: (userId: string) => {
    const { userId: currentUserId, listening } = get();
    if (listening && userId === currentUserId) return;
    if (userId !== currentUserId) {
      get().unsubscribe?.();
    }
    const unsubscribeFirebase = listenUserFiles(userId, (snapshot) => {
      const result = snapshot.val() as Record<FileId, File>;
      const files = Object.values(result ?? {})
        .filter((f) => f.isStudio)
        .sort((a, b) => b.modifiedDate - a.modifiedDate);
      set({ files });
    });
    set({
      listening: true,
      unsubscribe: () => {
        unsubscribeFirebase();
        set(() => ({ listening: false, unsubscribe: null, files: null }));
      },
      userId,
    });
  },
}));
