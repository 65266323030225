import { LanguagesIcon } from './Icons/LanguagesIcon.tsx';
import { useState } from 'react';
import { LegacyDashModal } from './LegacyDashModal.tsx';
import { Button } from './Button.tsx';

export function LegacyAppLink() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <LanguagesIcon className="h-6 w-6" />
        <div className="font-semibold">Non-English Voices</div>
      </Button>
      <LegacyDashModal open={open} setOpen={setOpen} />
    </>
  );
}
