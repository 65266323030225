import { ChangeEvent, ReactNode } from 'react';

export function Checkbox({
  name,
  value,
  checked,
  disabled,
  label,
  onChange,
}: {
  name: string;
  value: string | number;
  checked: boolean;
  disabled?: boolean;
  onChange: (elem: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
}) {
  return (
    <div className="flex items-center justify-center gap-3">
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className="relative h-4 w-4 cursor-pointer rounded dark:bg-neutral-950 text-primary focus:ring-2 focus:ring-primary focus:ring-offset-1 focus:ring-offset-neutral-950"
      />
      {label && <label>{label}</label>}
    </div>
  );
}
