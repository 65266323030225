import axios, { AxiosProgressEvent } from 'axios';
import { Gender } from '../pages/KettlePage/hooks/useVoices';
import { CONFIG } from '../config.ts';
import { VoiceAccent, VoiceGender, VoiceId, VoiceLoudness } from '../__playrepo/play-voice-domain/src/Voice.ts';
import { z } from 'zod';

type FileInfo = {
  ext: string;
  contentType: string;
};

type FileData = {
  token: string;
  fileInfo: FileInfo;
};

export type CloneVoiceParams = z.infer<typeof CloneVoiceParams>;
export const CloneVoiceParams = z.object({
  voiceId: VoiceId,
  originalSample: z.string().url(),
  originalSampleFileName: z.string(),
  name: z
    .string()
    .min(1)
    .max(100)
    .regex(/^[a-zA-Z0-9_\- ]+$/),
  description: z.string().max(500),
  gender: VoiceGender,
  accent: VoiceAccent.nullable(),
  loudness: VoiceLoudness.nullable(),
  style: z.string().nullable(),
  texture: z.string().nullable(),
  customAttributes: z.record(z.string().min(1).max(100)),
});

export const getPresignedURL = async (data: FileData) => {
  const res = await fetch(`${CONFIG.playStudioAPIBaseUrl}/voice-cloning/presignedurl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const response = await res.json();

  if (!res.ok) {
    throw new Error(response?.message || res.statusText);
  }

  return response;
};

export const uploadToS3 = async ({
  preSignedUrl,
  file,
  setUploadProgress,
}: {
  preSignedUrl: string;
  file: File;
  setUploadProgress: (progress: number) => void;
}) => {
  const data = await axios.request({
    method: 'put',
    url: preSignedUrl,
    data: file,
    onUploadProgress: (p: AxiosProgressEvent) => {
      if (p.total !== undefined) {
        setUploadProgress((p.loaded / p.total) * 100);
      }
    },
    headers: {
      'Content-Type': file.type,
    },
  });

  return data;
};

export type GuideClipT = {
  index?: number;
  data_index?: number;
  start: number;
  end: number;
  url: string;
  speaker: string;
};

export type VCRequestT = {
  token: string;
  name: string;
  training_data: string[];
  ar_epochs: number;
  diff_epochs: number;
  single_speaker: boolean;
  guide_clips?: GuideClipT[];
};

export const sendVCRequest = async (data = {} as VCRequestT) => {
  const res = await fetch(`${CONFIG.playStudioAPIBaseUrl}/voice-cloning/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const response = await res.json();

  if (!res.ok) {
    throw new Error(response?.message || res.statusText);
  }

  return response;
};

interface ZeroShotCloneRequest {
  voiceName: string;
  sampleFileURL: string;
  gender?: Gender;
  parentVoiceId?: string;
}

// TODO: Remove this (used by legacy)
export const createZeroShotClone = async (data: ZeroShotCloneRequest, token: string) => {
  return axios
    .post<{ id: string }>(`${CONFIG.playStudioAPIBaseUrl}/voice-cloning/create-zero-shot-clone`, data, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((res) => res.data.id);
};

export const deleteClonedVoice = (token: string, voiceID: string) =>
  axios.delete(`${CONFIG.playStudioAPIBaseUrl}/my-voices`, {
    data: {
      voiceID,
    },
    headers: {
      Authorization: `${token}`,
    },
  });

export const shareVoice = ({
  token,
  data,
}: {
  token: string;
  data: {
    voiceId: string;
    voiceName: string;
    isZeroShot: boolean;
  };
}) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/voice-cloning/cloned-voices/share`, {
    token,
    data,
  });

export const getSharedVoiceDetails = (hash: string) =>
  axios.get(`${CONFIG.playStudioAPIBaseUrl}/voice-cloning/cloned-voices/share/` + hash);

export const claimVoice = ({ token, hash }: { token: string; hash: string }) =>
  axios.post(`${CONFIG.playStudioAPIBaseUrl}/voice-cloning/cloned-voices/claim/` + hash, {
    token,
  });

export const updateClonedVoiceAPI = async (token: string, voiceId: string, gender: string) =>
  axios.put(
    `${CONFIG.playStudioAPIBaseUrl}/voice-cloning/cloned-voices/update`,
    {
      voiceId,
      gender,
    },
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );

export const generateVoiceCloneFiles = async (sampleUrl: string, token: string) => {
  return axios
    .post<{ originalVoiceId: VoiceId; enhancedVoiceId: VoiceId | null }>(
      `${CONFIG.roostBffAPIBaseUrl}/voice-cloning/generate-voice-clone-files`,
      { sampleUrl },
      { headers: { Authorization: `${token}` } }
    )
    .then((res) => res.data);
};

export const createVoiceClone = async (data: CloneVoiceParams, token: string) => {
  return axios
    .post<{ id: VoiceId }>(`${CONFIG.roostBffAPIBaseUrl}/voice-cloning/create-voice-clone`, data, {
      headers: { Authorization: `${token}` },
    })
    .then((res) => res.data.id);
};
