import { Spinner } from '../../components/Spinner';
import { CreateVoiceCloneButton } from './CreateVoiceCloneButton.tsx';
import { useClonedVoicesAndRequests } from '../../hooks/voiceCloning.hooks.ts';
import { useUserReachedHFCloningLimit, useUserReachedInstantCloningLimit } from '../../hooks/plans.hooks.ts';
import { VCTable } from './VCTable.tsx';
import { useEffect, useState } from 'react';
import { TextField } from '../../components/TextField.tsx';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { DeleteClonedVoicesButton } from './DeleteClonedVoicesButton.tsx';
import { useUser } from '../../contexts/user.context.tsx';

export function VCListingPage() {
  const { user } = useUser();
  const { isFetching, isFetchedAfterMount, clonedVoices, vcRequestsInFlight, vcRequestsAndVoices } =
    useClonedVoicesAndRequests();
  const instantCloningExceeded = useUserReachedInstantCloningLimit(clonedVoices);
  const hfCloningExceeded = useUserReachedHFCloningLimit(clonedVoices, vcRequestsInFlight);

  const [filteredRows, setFilteredRows] = useState(vcRequestsAndVoices);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [searchingValue, setSearchingValue] = useState('');

  useEffect(() => {
    if (searchingValue && vcRequestsAndVoices) {
      const newFilteredFiles = vcRequestsAndVoices.filter((file) =>
        file.name.toLowerCase().includes(searchingValue.toLowerCase())
      );
      setFilteredRows(newFilteredFiles);
      setSelectedRowIds(selectedRowIds.filter((fileId) => newFilteredFiles.some((f) => f.id === fileId)));
    } else {
      setFilteredRows(vcRequestsAndVoices);
    }
  }, [searchingValue, vcRequestsAndVoices, selectedRowIds]);

  const onDelete = (deletedRowIds: string[]) => {
    setSelectedRowIds(selectedRowIds.filter((id) => !deletedRowIds.includes(id)));
  };

  return (
    <div className="max-w-5xl">
      <h1 className="text-xl font-semibold">Voice Cloning</h1>
      {user?.isTeamMember ? (
        <p className="my-2">You are a team member, Only team admins can clone voices.</p>
      ) : (
        <CreateVoiceCloneButton instantCloningExceeded={instantCloningExceeded} hfCloningExceeded={hfCloningExceeded} />
      )}
      {isFetching && !isFetchedAfterMount ? (
        <Spinner className="mt-10" label="Fetching voice clones..." />
      ) : vcRequestsAndVoices.length === 0 ? (
        <span className="text-base font-semibold">You haven't created any voice clones yet</span>
      ) : (
        <>
          <div className="flex flex-wrap items-center justify-between gap-6">
            <div className="mt-6 w-96 max-tablet:w-full">
              <TextField
                name="search"
                value={searchingValue}
                placeholder="Search cloned voices"
                type="search"
                autoComplete="off"
                onChange={setSearchingValue}
                width="auto"
                leftIcon={<MagnifyingGlassIcon className="h-5 text-neutral" />}
              />
            </div>
            <div className="flex gap-3">
              <DeleteClonedVoicesButton voicesIds={selectedRowIds} onDelete={onDelete} />
            </div>
          </div>
          <VCTable rows={filteredRows} selectedRows={selectedRowIds} setSelectedRows={setSelectedRowIds} />
        </>
      )}
    </div>
  );
}
