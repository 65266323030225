import conversationalAiModelImage from '../../assets/whats-new/conversational-news-image.png';
import voiceEmotionsImage from '../../assets/whats-new/emotions-news-image.svg';
import stylesImage from '../../assets/whats-new/styles-news-image.svg';
import { FeatureFlags } from '../../hooks/useFeatureFlags.ts';

export type AnnouncementId = 'CONVERSATIONAL_AI_MODEL' | 'VOICE_EMOTIONS' | 'VOICE_STYLES';

export type Announcement = {
  id: AnnouncementId;
  featureFlag: keyof FeatureFlags | null;
  publishedAt: string;
  image: string;
  text: string;
};

export const ANNOUNCEMENTS = [
  {
    id: 'CONVERSATIONAL_AI_MODEL',
    featureFlag: 'ENABLE_EMOTIONS',
    publishedAt: '2023-10-09',
    image: conversationalAiModelImage,
    text: 'Introducing PlayHT 2.0, our latest voice model designed for generating humanlike conversational speech.',
  },
  {
    id: 'VOICE_EMOTIONS',
    featureFlag: 'ENABLE_EMOTIONS',
    publishedAt: '2023-10-09',
    image: voiceEmotionsImage,
    text: 'Discover Emotions in AI Voices with PlayHT 2.0. Start your new PlayHT 2.0 project now!',
  },
  {
    id: 'VOICE_STYLES',
    featureFlag: 'ENABLE_STYLES',
    publishedAt: '2030-01-01', // TODO: change when releasing styles
    image: stylesImage,
    text: '2.0 can clone any voice with its accent almost perfectly!',
  },
] as const satisfies ReadonlyArray<Announcement>;
