import { Dispatch, ReactNode, SetStateAction } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useActiveMainPlan } from '../../hooks/plans.hooks.ts';
import { Modal } from '../../components/Modal.tsx';
import { BoltIcon } from '@heroicons/react/24/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';

export function VCTypeSelectionModal({
  open,
  setOpen,
  onSelectType,
  instantCloningExceeded,
  hfCloningExceeded,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSelectType: (type: 'instant' | 'hf') => void;
  instantCloningExceeded: boolean;
  hfCloningExceeded: boolean;
}) {
  const navigate = useNavigate();
  const activePlan = useActiveMainPlan();
  return (
    <Modal open={open} setOpen={setOpen}>
      <h3 className="mb-8 text-center text-lg font-semibold">How would you like to clone your voice?</h3>
      <div className="grid grid-cols-2 gap-5 max-tablet:grid-cols-1">
        {renderOption(
          'Instant',
          <span>
            Only 30 seconds of
            <br />
            audio needed
          </span>,
          <BoltIcon className="h-9" />,
          () => onSelectType('instant'),
          navigate,
          instantCloningExceeded,
          activePlan ? 'Buy More Instant Clones' : 'Upgrade Plan to Access',
          !activePlan
        )}
        {renderOption(
          'High Fidelity',
          <span>
            Flawless results with 100% voice
            <br />
            and accent resemblance
          </span>,
          <SparklesIcon className="h-9" />,
          () => onSelectType('hf'),
          navigate,
          hfCloningExceeded,
          activePlan ? 'Buy More High Fidelity Clones' : 'Upgrade Plan to Access',
          !activePlan
        )}
      </div>
    </Modal>
  );
}

function renderOption(
  title: string,
  description: ReactNode,
  icon: ReactNode,
  onClick: () => void,
  navigate: NavigateFunction,
  isDisabled = false,
  upgradeMessage = 'Upgrade Plan to Access',
  redirectToPricePage = false
) {
  return (
    <div
      onClick={isDisabled && redirectToPricePage ? () => navigate('/studio/pricing') : onClick}
      className="flex h-[19rem] w-[19rem] cursor-pointer flex-col items-center justify-center gap-2 rounded-xl bg-neutral-900 p-4 text-center hover:bg-neutral-800/75 max-desktop:h-[16.5rem] max-desktop:w-[16.5rem] max-tablet:w-full"
    >
      <div
        className={clsx(
          'mb-4 rounded-full p-3',
          isDisabled ? 'bg-neutral/10 p-3 text-neutral' : 'bg-primary/10 p-3 text-primary'
        )}
      >
        {icon}
      </div>
      <p className={clsx('text-lg font-semibold', { 'text-neutral': isDisabled })}>{title}</p>
      <p className={clsx({ 'text-neutral': isDisabled })}>{description}</p>
      {isDisabled && <p className="absolute bottom-10 max-desktop:bottom-6 text-success underline">{upgradeMessage}</p>}
    </div>
  );
}
