'use client';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { Button } from '../../__playrepo/ui-components/src/Button.tsx';
import { generateZustandModal } from '../../__playrepo/ui-components/src/modal/ZustandModal';
import { TextField } from '../../__playrepo/ui-components/src/TextField.tsx';
import { TextArea } from '../../__playrepo/ui-components/src/TextArea.tsx';
import { type DropzoneFile, FilesDropzone } from '../../__playrepo/ui-components/src/FilesDropzone.tsx';
import { s3Utils } from '../../__playrepo/play-utils/src/s3/s3Utils.ts';
import {
  MAX_VOICE_SAMPLE_FILE_SIZE,
  MIN_VOICE_SAMPLE_FILE_SIZE,
  VOICE_SAMPLE_FILE_MIME_TYPES,
  type VoiceId,
} from '../../__playrepo/play-voice-domain/src/Voice.ts';
import { CloneVoiceParams, getPresignedURL } from '../../API/voiceCloning.requests.ts';
import { useAuth } from '../../contexts/auth.context.tsx';
import { useCreateVoiceClone, useGenerateVoiceCloneFiles } from '../../hooks/voiceCloning.hooks.ts';
import { TagsSelector, TagType } from '../../__playrepo/ui-components/src/TagsSelector.tsx';
import { GenderToggle } from './GenderToggle.tsx';
import { useErrorNotification } from '../../hooks/useErrorNotification.ts';
import { VoiceCloningConsentCheckbox } from './VoiceCloningConsentCheckbox.tsx';
import { useNotifications } from '../../__playrepo/ui-components/src/NotificationSnackbar.tsx';

const PREDEFINED_TAGS: TagType[] = [
  { key: 'age', value: 'Kid' },
  { key: 'age', value: 'Young' },
  { key: 'age', value: 'Middle-aged' },
  { key: 'age', value: 'Old person' },
  { key: 'accent', value: 'General American' },
  { key: 'accent', value: 'Southern American' },
  { key: 'accent', value: 'British' },
  { key: 'accent', value: 'Cockney' },
  { key: 'accent', value: 'Australian' },
  { key: 'accent', value: 'Scottish' },
  { key: 'accent', value: 'Irish' },
  { key: 'accent', value: 'Indian' },
  { key: 'language', value: 'English' },
  { key: 'language', value: 'Hindi' },
  { key: 'language', value: 'Spanish' },
  { key: 'language', value: 'Mandarin' },
  { key: 'language', value: 'Italian' },
  { key: 'language', value: 'French' },
  { key: 'language', value: 'German' },
  { key: 'language', value: 'Urdu' },
  { key: 'style', value: 'Narrative' },
  { key: 'style', value: 'Conversational' },
  { key: 'style', value: 'Meditation' },
];

export const InstantVoiceCloningModal = generateZustandModal<VoiceId>({
  className: 'w-full max-w-[41rem] text-left p-8 max-mobile:p-6',
  children: <VoiceCloningModalContent />,
});

function VoiceCloningModalContent() {
  const notifyError = useErrorNotification();
  const { setNotification } = useNotifications();
  const [consent, setConsent] = useState(false);
  const [params, setParams] = useState<Omit<CloneVoiceParams, 'voiceId'>>({
    name: '',
    description: '',
    originalSample: '',
    originalSampleFileName: '',
    gender: 'male',
    accent: null,
    loudness: null,
    style: null,
    texture: null,
    customAttributes: {},
  });
  const updateParams = (newParams: Partial<CloneVoiceParams>) => setParams((p) => ({ ...p, ...newParams }));

  const { uploadFile, uploadedSampleFile, deleteFile } = useUploadFile({
    onError: notifyError,
    onSuccess: ({ url, fileName }) => {
      updateParams({ originalSample: url, originalSampleFileName: fileName });
      generateVoiceCloneFiles(url);
    },
  });

  const {
    mutate: generateVoiceCloneFiles,
    isLoading: isGeneratingVoiceCloneFiles,
    data: voiceCloneFiles,
  } = useGenerateVoiceCloneFiles({
    onError: () => {
      setNotification({ status: 'error', message: 'Failed to generate voice clone files.' });
    },
  });

  const close = InstantVoiceCloningModal.useStore((state) => state.close);
  const { mutate: finishVoiceCloning, isLoading: isFinishingVoiceCloning } = useCreateVoiceClone({
    onError: () => setNotification({ status: 'error', message: 'Failed to clone voice.' }),
    onSuccess: (voiceId) => {
      setNotification({ status: 'success', message: 'Voice cloned successfully!' });
      close('onComplete', voiceId);
    },
  });

  const handleSendRequest = () => {
    if (!params.name) {
      setNotification({ status: 'error', message: 'Please name your voice clone.' });
      return;
    }

    if (!params.originalSample) {
      setNotification({ status: 'error', message: 'The voice sample needs to be uploaded before proceeding.' });
      return;
    }

    if (voiceCloneFiles) {
      finishVoiceCloning({ voiceId: voiceCloneFiles.originalVoiceId, ...params });
    } else {
      generateVoiceCloneFiles(params.originalSample);
    }
  };

  return (
    <form className="flex flex-col gap-8 max-mobile:gap-6" onSubmit={(event) => event.preventDefault()}>
      <div className="text-center text-lg font-medium">Create Your Voice Clone</div>
      <TextField
        name="voiceName"
        label="Name your voice clone"
        placeholder="Enter voice name"
        value={params.name}
        onChange={(value) => updateParams({ name: value })}
      />
      <FilesDropzone
        name="voiceSampleFile"
        label="Upload file"
        sublabel={
          <div>
            Upload a high quality audio sample. For best quality,{' '}
            <Link
              to="https://help.play.ht/en/article/voice-cloning-tips-1m8z242"
              target="_blank"
              className="cursor-pointer underline underline-offset-2"
            >
              read these tips for better results.
            </Link>
          </div>
        }
        icons={[<DocumentPlusIcon className="h-7 w-7" />]}
        description="Click to upload a file or drag & drop."
        subdescription={`[ .mp3, .wav, .m4a, .mp4, .AAC, ... up to ${MAX_VOICE_SAMPLE_FILE_SIZE / (1024 * 1024)}MB. ]`}
        buttonLabel={uploadedSampleFile ? 'Replace Audio File' : 'Upload High Quality Audio Sample'}
        buttonCta
        accept={VOICE_SAMPLE_FILE_MIME_TYPES}
        maxFiles={1}
        minSize={MIN_VOICE_SAMPLE_FILE_SIZE}
        maxSize={MAX_VOICE_SAMPLE_FILE_SIZE}
        files={uploadedSampleFile ? [uploadedSampleFile] : []}
        onFilesSelected={(files) => files[0] && uploadFile(files[0])}
        onFileDeleted={() => {
          updateParams({ originalSample: '', originalSampleFileName: '' });
          deleteFile();
        }}
      />
      <GenderToggle value={params.gender} onToggle={(gender) => updateParams({ gender })} />
      <div className="min-h-[150px]">
        <TagsSelector
          label="Describe this voice"
          sublabel="Selecting the correct tags improves quality of the voice clone."
          predefinedTags={PREDEFINED_TAGS}
          onChange={(a) => updateParams({ customAttributes: Object.fromEntries(a.map((a) => [a.key, a.value])) })}
        />
      </div>
      <TextArea
        name="voiceDescription"
        label="Description"
        sublabel="How would you describe this voice?"
        placeholder="e.g. A middle-aged American man, with a calm soothing tone. His cadence is rhythmic and poetic, his enthusiasm: moderate, contemplative."
        value={params.description}
        onChange={(value) => updateParams({ description: value })}
        className="min-h-[10rem]"
      />
      <VoiceCloningConsentCheckbox consent={consent} setConsent={setConsent} />
      <Button
        intent="cta"
        size="large"
        className="w-full"
        disabled={!consent}
        onClick={() => handleSendRequest()}
        loading={isGeneratingVoiceCloneFiles || isFinishingVoiceCloning}
      >
        {isFinishingVoiceCloning ? 'Cloning Voice...' : 'Clone Voice'}
      </Button>
    </form>
  );
}

function useUploadFile({
  onSuccess,
  onError,
}: {
  onSuccess: (data: { url: string; fileName: string }) => void;
  onError: (e: unknown) => void;
}) {
  const { currentUser: user } = useAuth();

  const [uploadedSampleFile, setUploadedSampleFile] = useState<DropzoneFile | null>(null);
  const deleteFile = () => setUploadedSampleFile(null);
  const updateUploadedSampleFile = (newFile: Partial<DropzoneFile>) =>
    setUploadedSampleFile((prev) => (!prev ? prev : { ...prev, ...newFile }));

  const { mutate: uploadFile } = useMutation({
    mutationFn: async (file: File) => {
      if (!user) return;
      const token = await user.getIdToken();
      const fileName = file.name;
      const fileExt = fileName.split('.').pop();
      if (!fileExt) throw new Error("File doesn't have an extension");
      setUploadedSampleFile({ file, progress: 0 });
      const fileInfo = { ext: fileExt, contentType: file.type };
      try {
        const { preSignedUrl, url } = await getPresignedURL({ token, fileInfo });
        updateUploadedSampleFile({ url });
        await s3Utils.uploadToS3WithPresignedUrl(preSignedUrl, file, (p) => updateUploadedSampleFile({ progress: p }));
        return { url, fileName };
      } catch (e) {
        throw new Error('Failed to upload file.');
      }
    },
    onSuccess: (data) => data && onSuccess(data),
    onError,
  });

  return { uploadFile, uploadedSampleFile, deleteFile };
}
