'use client';

import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid/index.js';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type NotificationStatusT = 'success' | 'error';
export const NOTIFICATION_TYPE_SUCCESS: NotificationStatusT = 'success';
export const NOTIFICATION_TYPE_ERROR: NotificationStatusT = 'error';

export interface Notification {
  id: string;
  message: string;
  status: NotificationStatusT;
}

export type SetNotificationPayload = {
  message: string;
  status: NotificationStatusT;
};

export type SetNotification = (notification: SetNotificationPayload) => void;

const NotificationContext = createContext<{ setNotification: SetNotification }>({ setNotification: () => null });

export const NotificationsProvider = ({ children }: { children: JSX.Element }) => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  const setNotification = useCallback(
    ({ message, status }: SetNotificationPayload) => {
      const id = uuid();

      setNotifications((prev) => [
        ...prev,
        {
          id,
          message,
          status,
        },
      ]);

      setTimeout(() => {
        setNotifications((prev) => prev.filter((notification) => notification.id !== id));
      }, 5000);
    },
    [setNotifications]
  );

  const clearNotification = useCallback(
    (id: string) => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    },
    [setNotifications]
  );

  const value = useMemo(() => ({ setNotification }), [setNotification]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {notifications && notifications.length > 0 && (
        <div className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:mt-16 sm:items-start sm:p-6">
          <div className="flex w-full flex-col items-center space-y-4 mobile:items-end">
            {notifications.map(({ id, message, status }) => (
              <NotificationSnackbar
                key={id}
                id={id}
                message={message}
                status={status}
                onClickClose={clearNotification}
              />
            ))}
          </div>
        </div>
      )}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);

export function NotificationSnackbar({
  id,
  message,
  status,
  onClickClose,
}: {
  id: string;
  message: string;
  status: NotificationStatusT;
  onClickClose: (id: string) => void;
}) {
  return (
    <div
      className={clsx(
        'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg',
        status === 'success' ? 'bg-primary-green-600 text-black' : 'bg-red-500 text-white'
      )}
    >
      <div className="flex items-start gap-3 p-4">
        <div className="shrink-0">
          {status === NOTIFICATION_TYPE_SUCCESS ? (
            <CheckCircleIcon className="h-7 w-7" aria-hidden="true" />
          ) : status === NOTIFICATION_TYPE_ERROR ? (
            <ExclamationCircleIcon className="h-7 w-7" aria-hidden="true" />
          ) : null}
        </div>
        <div className="mt-0.5 flex-1 font-medium">
          <p>{message}</p>
        </div>
        <div className="flex shrink-0">
          <button className="inline-flex cursor-pointer" onClick={() => onClickClose(id)}>
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-7 w-7" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
