import { Button } from './Button.tsx';
import { CheckIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Label, TextField } from './TextField.tsx';
import { useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { clsx } from 'clsx';

export interface TagsSelectorProps {
  label?: string;
  sublabel?: string;
  predefinedTags: TagType[];
  onChange: (selectedTags: TagType[]) => void;
}

export interface TagType {
  key: string;
  value: string;
  custom?: true;
}

type SelectableTags = Record<string, SelectableTag>;
type SelectableTag = TagType & { selected?: boolean; disabled?: boolean };

export function TagsSelector({ predefinedTags, onChange, label, sublabel }: TagsSelectorProps) {
  const [tagsAnimation] = useAutoAnimate();
  const [showInputsForCustomTag, setShowInputsForCustomTag] = useState(false);
  const [customTagKey, setCustomTagKey] = useState('');
  const [customTagValue, setCustomTagValue] = useState('');
  const [tags, setTags] = useState<SelectableTags>(byUniqueKey(predefinedTags));

  function selectTag(tag: TagType) {
    const tagUniqueKey = getTagUniqueKey(tag);
    const updateSelectedAndDisabledFields = (t: SelectableTag) => ({
      ...t,
      selected: t.key === tag.key ? false : t.selected,
      disabled: t.key === tag.key ? true : t.disabled,
    });
    const newTags = {
      ...byUniqueKey(getSelectedTags(tags).map(updateSelectedAndDisabledFields)),
      [tagUniqueKey]: { ...tag, selected: true as const },
      ...byUniqueKey(
        getNonSelectedTags(tags)
          .filter((t) => getTagUniqueKey(t) !== tagUniqueKey)
          .map(updateSelectedAndDisabledFields)
      ),
    };
    setTags(newTags);
    onChange(getSelectedTags(newTags));
  }

  function deleteTag(tag: TagType) {
    const tagUniqueKey = getTagUniqueKey(tag);
    const newTags = {
      ...byUniqueKey(getSelectedTags(tags)),
      [tagUniqueKey]: { ...tag, selected: false },
      ...byUniqueKey(getNonSelectedTags(tags).map((t) => ({ ...t, disabled: t.key === tag.key ? false : t.disabled }))),
    };
    setTags(newTags);
    onChange(getSelectedTags(newTags));
  }

  function addCustomTag() {
    if (!customTagKey || !customTagValue) return;
    const newTag: TagType = { key: customTagKey, value: customTagValue, custom: true };
    selectTag(newTag);
    setCustomTagKey('');
    setCustomTagValue('');
    setShowInputsForCustomTag(false);
  }

  return (
    <div>
      <Label sub={sublabel}>{label}</Label>
      <ul ref={tagsAnimation} className="flex flex-wrap gap-2 overflow-hidden p-[1px]">
        {getSelectedTags(tags).map((tag) => (
          <li key={getTagUniqueKey(tag)}>
            <SelectedTag tag={tag} onSelect={deleteTag} />
          </li>
        ))}
        {getNonSelectedTags(tags).map((tag) => (
          <li key={getTagUniqueKey(tag)}>
            <NonSelectedTag tag={tag} onSelect={selectTag} />
          </li>
        ))}
        <li key="__add_tag__">
          <AddTag onClick={() => setShowInputsForCustomTag(!showInputsForCustomTag)} />
        </li>
      </ul>
      <div
        className={clsx(
          'mt-4 flex flex-nowrap gap-4 items-end overflow-hidden transition-all duration-300',
          showInputsForCustomTag ? 'p-[1px] h-[80px] opacity-100' : 'p-0 h-0 opacity-0'
        )}
      >
        <TextField name="customTagKey" label="Voice Attribute Key" value={customTagKey} onChange={setCustomTagKey} />
        <TextField
          name="customTagValue"
          label="Voice Attribute Value"
          value={customTagValue}
          onChange={setCustomTagValue}
        />
        <Button intent="neutral" className="w-[180px] text-sm" onClick={addCustomTag}>
          <CheckIcon className="w-4 h-4" />
          <span>Save attribute</span>
        </Button>
      </div>
    </div>
  );
}

export function SelectedTag({ tag, onSelect }: { tag: SelectableTag; onSelect: (tag: SelectableTag) => void }) {
  return (
    <Button rounded size="small" intent="cta" onClick={() => onSelect(tag)}>
      <span>{tag.value}</span>
      <XMarkIcon className="w-5 h-5" />
    </Button>
  );
}

export function NonSelectedTag({ tag, onSelect }: { tag: SelectableTag; onSelect: (tag: SelectableTag) => void }) {
  return (
    // TODO: Create new intent or just add a ring
    <Button
      rounded
      bordered
      size="small"
      intent="opaque-green-plus"
      disabled={tag.disabled}
      onClick={() => onSelect(tag)}
    >
      <PlusCircleIcon className="w-[1.33rem] h-[1.33rem]" />
      <span>{tag.value}</span>
    </Button>
  );
}

export function AddTag({ onClick }: { onClick: () => void }) {
  return (
    // TODO: Create blue intent
    <Button rounded bordered size="small" intent="opaque-blue-plus" onClick={onClick}>
      <PlusCircleIcon className="w-[1.33rem] h-[1.33rem]" />
      <span>Add New Label</span>
    </Button>
  );
}

function getSelectedTags(tags: SelectableTags) {
  return Object.values(tags).filter((t) => t.selected);
}

function getNonSelectedTags(tags: SelectableTags) {
  return Object.values(tags).filter((t) => !t.selected);
}

function getTagUniqueKey(tag: SelectableTag) {
  return `${tag.key}:${tag.value}`;
}

function byUniqueKey(tags: TagType[]) {
  return tags.reduce(
    (acc, tag) => {
      acc[getTagUniqueKey(tag)] = tag;
      return acc;
    },
    {} as Record<string, SelectableTag>
  );
}
