import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Modal } from './Modal.tsx';
import { LanguagesIcon } from './Icons/LanguagesIcon.tsx';
import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FileModel } from '../domain/files/File.ts';

export function FileModelSelectorModal({
  open,
  setOpen,
  createFile,
  redirectToStandard,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  createFile: (model: FileModel) => void;
  redirectToStandard: () => void;
}) {
  return (
    <Modal open={open} setOpen={setOpen} showXToClose maxWidth="40rem">
      <div className="flex flex-col gap-8 dark:text-neutral-200">
        <h2 className="font-semibold text-xl text-center">Choose a voice model</h2>
        <Box
          onClick={() => createFile('PlayHT2.0-gargamel')}
          title="PlayHT 2.0 Gargamel"
          firstTag={<TopTag className="bg-primary-green-600 !text-black">New</TopTag>}
          secondTag="English Only"
          rightIcon={<ChevronRightIcon className="h-6 w-6" />}
          description="Our latest version of PlayHT 2.0 with higher quality, reliability and better voice and accent cloning."
        />
        <Box
          onClick={() => createFile('PlayHT2.0')}
          title="PlayHT 2.0"
          firstTag={<TopTag className="bg-primary text-neutral-100">Beta</TopTag>}
          secondTag="English Only"
          rightIcon={<ChevronRightIcon className="h-6 w-6" />}
          description="Our second generation voice model built for Conversational Speech and Emotions."
        />
        <Box
          onClick={() => createFile('PlayHT1.0')}
          title="PlayHT 1.0"
          secondTag="English Only"
          rightIcon={<ChevronRightIcon className="h-6 w-6" />}
          description="Our first voice model built for generating human-like speech. Offers a large library of pre-built voices to choose from."
        />
        <Box
          onClick={redirectToStandard}
          title="Standard"
          firstTag={<LanguagesIcon className="h-5 w-5" />}
          secondTag="100+ Languages"
          rightIcon={<ArrowTopRightOnSquareIcon className="h-6 w-6" />}
          description="Multi-lingual text to speech voices with consistent outputs. Choose from 900+ voices across 100+ languages that support pauses, emphasis, tones and pronunciations."
        />
      </div>
    </Modal>
  );
}

function Box({
  highlighted,
  title,
  firstTag,
  secondTag,
  rightIcon,
  description,
  onClick,
}: {
  highlighted?: boolean;
  title: string;
  firstTag?: ReactNode;
  secondTag?: string;
  rightIcon: ReactNode;
  description: string;
  onClick: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className={clsx('cursor-pointer p-4 rounded-lg dark:bg-neutral-900 ring-transparent ring-2 hover:ring-gray-500', {
        'ring-4 ring-green-900': highlighted,
      })}
    >
      <div className="flex gap-4 items-center">
        <div className="flex-grow flex flex-col gap-2">
          <div className="flex items-center gap-2.5">
            <div className="font-semibold text-xl">{title}</div>
            {firstTag}
            <div>
              <span className="text-xs bg-neutral-700 px-1.5 py-1 rounded-md">{secondTag}</span>
            </div>
          </div>
          <div className="text-base">{description}</div>
        </div>
        <div>{rightIcon}</div>
      </div>
    </div>
  );
}

function TopTag({ children, className }: { children: ReactNode; className?: string }) {
  return (
    <div className={clsx('py-0.5 px-2.5 rounded-md font-semibold text-xs text-neutral-200', className)}>{children}</div>
  );
}
