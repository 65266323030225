import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FileSelector } from '../../components/FileSelector';
import { useUser } from '../../contexts/user.context.tsx';
import { useAuth } from '../../contexts/auth.context';
import { getPresignedURL, GuideClipT, uploadToS3 } from '../../API/voiceCloning.requests';
import { AudioClipper, hasMinimumClippings } from '../../components/AudioClipper/AudioClipper.jsx';
import { Modal } from '../../components/Modal.tsx';
import { TextField } from '../../components/TextField.tsx';
import { Checkbox } from '../../components/Checkbox.tsx';
import { Divider } from '../../components/Divider.tsx';
import { ExpandableText } from '../../components/ExpandableText.tsx';
import { TextLink } from '../../components/TextLink.tsx';
import { Button } from '../../components/Button.tsx';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlayIcon } from '@heroicons/react/24/solid';
import { useCloneHFVoice } from '../../hooks/voiceCloning.hooks.ts';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  useNotifications,
} from '../../__playrepo/ui-components/src/NotificationSnackbar.tsx';

export interface IAudioURL {
  url: string;
  name: string;
}

export function HFVoiceCloningModal({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <HFVoiceCloningModalContent setOpen={setOpen} />
    </Modal>
  );
}

function HFVoiceCloningModalContent({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) {
  const { user: userInfo } = useUser();
  const { currentUser: user } = useAuth();
  const { setNotification } = useNotifications();

  const [speakerName, setSpeakerName] = useState('');
  const [audioURLs, setAudioURLs] = useState<IAudioURL[]>([]);

  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [hasMultipleSpeakers, setHasMultipleSpeakers] = useState(false);
  const [clippings, setClippings] = useState<GuideClipT[] | undefined>(undefined);

  const [ar_epochs, setArEpochs] = useState<number>(40);
  const [diff_epochs, setDiffEpochs] = useState<number>(100);

  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const { isLoading: isSubmitting, mutate: submit } = useCloneHFVoice(() => setOpen(false));

  useEffect(() => {
    setIsDisabled(
      !speakerName ||
        audioURLs.length < 1 ||
        isUploading ||
        isSubmitting ||
        (hasMultipleSpeakers && !clippings) ||
        (hasMultipleSpeakers && !hasMinimumClippings(audioURLs, clippings)) ||
        !agreeToTerms
    );
  }, [speakerName, audioURLs, isUploading, isSubmitting, hasMultipleSpeakers, clippings, agreeToTerms]);

  const uploadFile = async (files: File[]) => {
    if (!files.length) {
      setNotification({
        message: 'Please upload an audio file',
        status: NOTIFICATION_TYPE_ERROR,
      });
      return false;
    }
    if (!user) return;

    setIsUploading(true);
    try {
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        const fileName = file.name;
        const allowedExtensions = ['mp3', 'wav'];
        const fileExtension = fileName.split('.').pop();

        // check if file at least 1MB in size
        if (file.size < 1000000) {
          throw new Error(file.name + ' error, Each file size should be least 1MB');
        }

        if (!fileName) {
          throw new Error('File name not found');
        }

        if (typeof fileName !== 'string') {
          throw new Error('File name is not a string');
        }

        // check if file extension is other than mp3 or wav
        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          throw new Error(fileName + ' error, Only mp3 and wav files are allowed');
        }

        const token = await user.getIdToken();

        setCurrentFile(file);

        const { preSignedUrl, url } = await getPresignedURL({
          token,
          fileInfo: {
            ext: fileExtension,
            contentType: file.type,
          },
        });

        await uploadToS3({
          preSignedUrl,
          file,
          setUploadProgress,
        });

        setAudioURLs((prev) => [
          ...prev,
          {
            url,
            name: file.name,
          },
        ]);
      }

      setNotification({
        status: NOTIFICATION_TYPE_SUCCESS,
        message: 'Files uploaded successfully',
      });
    } catch (error: unknown) {
      setNotification({
        message: (error as Error)?.message ?? 'Something went wrong',
        status: NOTIFICATION_TYPE_ERROR,
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      setCurrentFile(null);
    }
  };

  const handleDeleteAudio = (index: number) => {
    setAudioURLs((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="max-w-[520px]">
      <h1 className="mb-12 text-xl font-semibold flex items-center">
        Let's create your High Fidelity Voice Clone
        <span className="ml-2 py-0.5 px-1.5 rounded-md font-semibold text-xs bg-success text-neutral-800">
          PlayHT1.0
        </span>
      </h1>
      <div className="mb-6">
        <TextField
          name="voiceName"
          label="Voice Name"
          value={speakerName}
          placeholder="Enter voice name"
          width="auto"
          onChange={(value) => setSpeakerName(value)}
        />
      </div>

      <label className="mb-3 block">Upload High Quality Audio sample</label>

      {/* if admin , allow controlling ar_epochs and diff_epochs */}
      {userInfo?.isAdmin && (
        <div>
          <div className="mb-6 flex flex-col gap-2 ">
            <label className="text-sm font-medium">AR epochs</label>
            <input
              type="number"
              className="rounded-lg border-[.5px]"
              value={ar_epochs}
              onChange={(event) => setArEpochs(Number(event.target.value))}
            />
          </div>
          <div className="mb-6 flex flex-col gap-2 ">
            <label className="text-sm font-medium">Diff epochs</label>
            <input
              type="number"
              className="rounded-lg border-[.5px]"
              value={diff_epochs}
              onChange={(event) => setDiffEpochs(Number(event.target.value))}
            />
          </div>
        </div>
      )}

      {isUploading ? (
        <div className="flex h-[80px] flex-col items-center justify-center gap-2 rounded-xl bg-neutral-900/50 ring-1 ring-inset ring-neutral-800/50 transition-all">
          <label className="block overflow-ellipsis font-semibold">Uploading: {currentFile?.name}</label>
          <div className="w-full">
            <div className="mx-auto mt-2 h-2 w-9/12 rounded-xl bg-neutral-700">
              <div className="h-full rounded-xl bg-primary" style={{ width: `${uploadProgress}%` }} />
            </div>
          </div>
        </div>
      ) : (
        <div className="transition-all">
          <FileSelector name="audioUploader" uploadFile={uploadFile} accept={{ 'audio/*': [] }} multiple />
        </div>
      )}

      <p className="mt-3 text-xs">
        The sample file can be in either mp3 or wav format. High quality audio will bring better results, aim to
        minimize background noise. Upload 30 minutes or more for the best result.
      </p>

      {audioURLs.length > 0 && !hasMultipleSpeakers && (
        <div className="mt-4 flex flex-col gap-2">
          <div className="mb-2">Files Uploaded</div>
          <div className="space-y-2">
            {audioURLs.map((file, index) => (
              <div key={index} className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <div className="rounded-full bg-primary/10 p-1">
                    <PlayIcon className="h-7 w-7 text-primary" />
                  </div>
                  <div>{file.name}</div>
                </div>
                <div className="cursor-pointer underline" onClick={() => handleDeleteAudio(index)}>
                  <TrashIcon className="h-5 w-5" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="my-8 inline-block ">
        <Checkbox
          name="hasMultipleSpeakers"
          value="hasMultipleSpeakers"
          label="Check this box if there are multiple speakers in the recordings"
          checked={hasMultipleSpeakers}
          onChange={(elem) => setHasMultipleSpeakers(elem.target.checked)}
        />
      </div>

      {audioURLs.length > 0 && hasMultipleSpeakers && (
        <AudioClipper
          urls={audioURLs}
          onFinish={(clippings: GuideClipT[]) => setClippings(clippings)}
          speaker={speakerName}
          onSpeakerUpdate={(speaker: string) => setSpeakerName(speaker)}
        />
      )}

      {/* agree to terms checkbox */}
      <div className="mb-6 mt-16">
        <Divider />
      </div>
      <div className="my-8">
        <Checkbox
          name="agreeToTerms"
          value="agreeToTerms"
          checked={agreeToTerms}
          onChange={(elem) => setAgreeToTerms(elem.target.checked)}
          label={
            <div className="flex flex-col gap-2">
              <ExpandableText resumeText="I hereby confirm that I have all the necessary rights or consents to clone and use this voice... ">
                <>
                  <p>
                    I hereby confirm that I have all the necessary rights or consents to clone and use this voice
                    without violating any copyrights and rights of publicity.
                  </p>{' '}
                  <p>
                    I also confirm that I have the rights to the audio files used in cloning this voice, and I will not
                    use this or any other voice on{' '}
                    <a className="" href="https://play.ht/privacy/">
                      PlayHT
                    </a>{' '}
                    to generate any sexual, hateful, abusive, violent, illegal, fraudulent, or deceitful content
                    whatsoever.
                  </p>{' '}
                  <p>
                    I fully understand and agree to PlayHT’s
                    <TextLink to="https://play.ht/terms/"> Terms of Use</TextLink> and
                    <TextLink to="https://play.ht/privacy/"> Privacy Policy</TextLink>.
                  </p>
                </>
              </ExpandableText>
            </div>
          }
        />
      </div>

      {/* submit button */}
      <div className="mt-8 flex justify-end">
        <Button
          intent="primary"
          width="full"
          disabled={isDisabled}
          loading={isSubmitting}
          onClick={() =>
            submit({
              audioUrls: audioURLs.map((a) => a.url),
              speakerName,
              hasMultipleSpeakers,
              clippings,
              ar_epochs,
              diff_epochs,
            })
          }
        >
          Create
        </Button>
      </div>
    </div>
  );
}
