import { type ReactNode, useState } from 'react';
import { clsx } from 'clsx';
import { DocumentDuplicateIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { copyTextToClipboard } from '../../play-ai-domain/src/utils/stringUtils';

export interface TextFieldProps {
  name: string;
  type?: 'text' | 'email' | 'password' | 'search';
  label?: string;
  sublabel?: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  leftIcon?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  showOption?: boolean;
  copyOption?: boolean;
  autoComplete?: string;
  className?: string;
}

export function TextField({
  name,
  type = 'text',
  label,
  sublabel,
  value,
  placeholder = '',
  onChange = () => ({}),
  leftIcon,
  autoComplete,
  required = false,
  disabled = false,
  showOption = false,
  copyOption = false,
  className,
}: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={className}>
      {label && (
        <Label htmlFor={name} sub={sublabel}>
          {label}
        </Label>
      )}
      <div className="flex rounded-xl">
        <div className="relative flex grow items-stretch">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{leftIcon}</div>
          <input
            id={name}
            name={name}
            type={type === 'password' && showPassword ? 'text' : type}
            autoComplete={autoComplete}
            required={required}
            disabled={disabled}
            className={clsx(
              'block w-full rounded-2xl border-0 bg-gray-800 px-4 py-3 font-medium shadow-sm outline-0 ring-1 ring-gray-700 ring-offset-0 placeholder:text-gray-600 focus:ring-2 focus:ring-primary-green-500 disabled:cursor-not-allowed disabled:bg-white/5 disabled:text-gray-600',
              leftIcon ? 'pl-10' : 'pl-4'
            )}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
          />
          {showOption && (
            <RightOption action={() => setShowPassword(!showPassword)}>
              {showPassword ? <EyeIcon className="h-5 w-5" /> : <EyeSlashIcon className="h-5 w-5 " />}
            </RightOption>
          )}
          {copyOption && (
            <RightOption action={() => copyTextToClipboard(value)}>
              <DocumentDuplicateIcon className="h-5 w-5" />
            </RightOption>
          )}
        </div>
      </div>
    </div>
  );
}

export function Label({ children, htmlFor, sub }: { children: ReactNode; htmlFor?: string; sub?: ReactNode }) {
  return (
    <div className="mb-2.5 flex flex-col gap-1">
      <label htmlFor={htmlFor} className="block text-xs font-medium uppercase">
        {children}
      </label>
      {sub && (
        <label htmlFor={htmlFor} className="block text-xs text-gray-200/70">
          {sub}
        </label>
      )}
    </div>
  );
}

function RightOption({ children, action }: { children: ReactNode; action?: () => void }) {
  return (
    <button
      type="button"
      className="disabled: relative inline-flex items-center gap-x-1.5 bg-gray-200/5 px-3 py-2 font-semibold ring-1 ring-inset ring-gray-600 last:rounded-r-md hover:bg-gray-500/10"
      onClick={action}
    >
      {children}
    </button>
  );
}
