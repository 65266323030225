import { clsx } from 'clsx';
import { useEffect, useRef } from 'react';
import { Label } from './TextField';

export function TextArea({
  name,
  label,
  sublabel,
  value,
  onChange = () => null,
  placeholder,
  charsLimit,
  className,
}: {
  name: string;
  label?: string;
  sublabel?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  charsLimit?: number;
  className?: string;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (!textareaRef.current) return;
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = `${scrollHeight}px`;
  }, [value]);
  return (
    <div className="flex w-full flex-col">
      <Label htmlFor={name} sub={sublabel}>
        {label}
      </Label>
      <div className="relative flex flex-row justify-between">
        <textarea
          ref={textareaRef}
          id={name}
          name={name}
          className={clsx(
            className,
            'block w-full resize-none rounded-2xl border-0 bg-gray-800 px-4 py-3 font-medium shadow-sm outline-0 ring-1 ring-gray-700 ring-offset-0 placeholder:text-gray-600 focus:ring-2 focus:ring-primary-green-500 disabled:cursor-not-allowed disabled:bg-white/5 disabled:text-gray-600',
            charsLimit ? 'pb-12' : 'pb-4'
          )}
          rows={4}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
        <div className="absolute bottom-4 left-4 flex flex-col justify-end">
          {!charsLimit ? null : (
            <span
              className={clsx('text-xs', value.length <= charsLimit ? 'text-gray-600' : 'text-red-500')}
            >{`${value.length}/${charsLimit}`}</span>
          )}
        </div>
      </div>
    </div>
  );
}
