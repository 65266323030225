import { useUser } from '../contexts/user.context.tsx';
import { Link } from 'react-router-dom';
import { Button } from './Button.tsx';
import { formatNumberWithCommas } from '../utils/numberUtils.ts';
import { useActiveMainPlan } from '../hooks/plans.hooks.ts';

export function CreditsCounter({ onClick }: { onClick?: () => void }) {
  const { user } = useUser();
  const activePlan = useActiveMainPlan();
  const hasUnlimitedCredits = activePlan?.hasUnlimitedCredits;
  const isMeteredPlan = activePlan?.is_metered_plan ?? false;
  const charsCountExists = !(user?.usage?.chars_count ?? false);
  const wordsCount = user?.usage?.words_count ?? 0;
  const charsCount = user?.usage?.chars_count ?? 0;
  const charsOverQuota = user?.usage?.current_metered_char_usage;

  if (user?.isTeamMember) {
    return null;
  }

  return (
    <div className="space-y-2 rounded-xl bg-neutral-800 p-4 text-center text-gray">
      {hasUnlimitedCredits ? null : (
        <>
          {wordsCount ? <div className="text-xs">{formatNumberWithCommas(wordsCount)} words remaining</div> : null}
          {wordsCount && charsCount ? <div className="text-xs">and</div> : null}
          {charsCount ? <div className="text-xs">{formatNumberWithCommas(charsCount)} characters remaining</div> : null}
          {!wordsCount && !charsCount && !isMeteredPlan ? (
            <div className="text-xs">You've run out of {charsCountExists ? 'characters' : 'words'}</div>
          ) : null}
          {charsOverQuota ? (
            <div className="text-xs">{formatNumberWithCommas(charsOverQuota)} characters over quota</div>
          ) : null}
        </>
      )}
      <Link to="/studio/pricing" className="block">
        <Button intent="primary" size="small" width="full" onClick={onClick}>
          Upgrade
        </Button>
      </Link>
    </div>
  );
}
