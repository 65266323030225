import {
  CloudArrowUpIcon,
  DocumentTextIcon,
  PhotoIcon,
  SpeakerWaveIcon,
  TrashIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { type DropzoneProps, useDropzone } from 'react-dropzone';
import { type ReactNode } from 'react';
import { Label } from './TextField';
import { Button } from './Button';

export interface DropzoneFile {
  file: File;
  progress: number;
  url?: string;
}

export interface FilesDropzoneProps extends DropzoneProps {
  name: string;
  label: ReactNode;
  sublabel?: ReactNode;
  description: ReactNode;
  subdescription?: ReactNode;
  icons?: Array<ReactNode>;
  buttonLabel?: ReactNode;
  buttonCta?: true;
  files?: Array<DropzoneFile>;
  onFilesSelected: (files: Array<File>) => void;
  onFileDeleted: (file: File) => void;
}

export function FilesDropzone({
  name,
  label,
  sublabel,
  description,
  subdescription,
  icons,
  buttonLabel,
  buttonCta,
  files,
  onFilesSelected,
  onFileDeleted,
  maxFiles = 1,
  ...dropzoneProps
}: FilesDropzoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneProps,
    onDrop: onFilesSelected,
    multiple: maxFiles > 1,
    maxFiles,
  });
  const buttonIntent = !files?.length && buttonCta ? 'cta' : 'neutral';
  return (
    <div>
      <Label sub={sublabel}>{label}</Label>
      <section
        {...getRootProps()}
        className="flex cursor-pointer flex-col content-around items-center justify-center rounded-lg border border-dashed border-primary-green-400/20 bg-primary-green-600 bg-opacity-[0.08] px-4 py-6 text-center"
      >
        <input name={name} {...getInputProps()} />
        <div className="mb-2 inline-flex gap-2">{icons?.map((icon, i) => <div key={i}>{icon}</div>)}</div>
        <div className="mb-4">
          <div className="text-xs font-medium">{description}</div>
          {subdescription && <div className="text-xs opacity-60">{subdescription}</div>}
        </div>
        <Button intent={buttonIntent} className="mt-2 w-full">
          <CloudArrowUpIcon className="h-7 w-7" />
          {buttonLabel || 'Select Files'}
        </Button>
      </section>
      {(files?.length ?? 0) > 0 && (
        <ul className="mt-4 flex flex-col gap-4">
          {files?.map(({ file, progress, url }) => (
            <li
              key={file.name}
              className="my-2 flex items-center gap-4 rounded-xl border-4 border-gray-700/30 bg-gray-800/50 px-4 py-6"
            >
              {getFileIcon(file)}
              <div className="w-1 flex-1 text-left">
                <a
                  href={url}
                  target="_blank"
                  className="mb-1 truncate font-mono font-medium underline-offset-2 hover:underline"
                  rel="noreferrer"
                >
                  {file.name}
                </a>
                <p className="text-xs opacity-60">
                  {file.type && getFileSubtype(file)} file &middot; last updated{' '}
                  {new Date(file.lastModified).toDateString()}
                </p>
              </div>
              {progress < 100 && (
                <div className="h-2 min-w-20 rounded-xl bg-gray-700">
                  <div className="h-full rounded-xl bg-primary-green-600" style={{ width: `${progress}%` }} />
                </div>
              )}
              <button className="relative left-3 p-3" onClick={() => onFileDeleted(file)}>
                <TrashIcon className="h-6 w-6 text-red-500" />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function getFileIcon(file: File) {
  switch (getFileType(file)) {
    case 'image':
      return <PhotoIcon className="h-6 w-6" />;
    case 'video':
      return <VideoCameraIcon className="h-6 w-6" />;
    case 'audio':
      return <SpeakerWaveIcon className="h-6 w-6" />;
    default:
      return <DocumentTextIcon className="h-6 w-6" />;
  }
}

function getFileType(file: File) {
  return file.type?.split('/')[0]?.toLowerCase();
}

function getFileSubtype(file: File) {
  return file.type?.split('/')[1]?.toUpperCase();
}
