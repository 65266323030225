import { AuthProvider } from './contexts/auth.context.tsx';
import { Outlet } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { PaymentSuccesfulModal } from './components/PaymentSuccesfulModal.tsx';
import { ClarityTacking } from './components/ClarityTracking.tsx';
import { ErrorPage } from './pages/Error/ErrorPage.tsx';
import { UserProvider } from './contexts/user.context.tsx';
import { ConfigCatProvider, PollingMode } from 'configcat-react';
import { WelcomeTeamMemberModal } from './components/WelcomeTeamMemberModal.tsx';
import { SentryFatalErrorBoundary } from './infra/errors/SentryErrorBoundary.tsx';
import { errorsTracker } from './infra/errors/errorsTracker.ts';
import { CONFIG } from './config.ts';
import { StripeProvider } from './infra/payment/StripeProvider.tsx';
import { AnnouncementsJournal } from './components/News/AnnouncementsJournal.tsx';
import { InstantVoiceCloningModal } from './pages/VoiceCloning/InstantVoiceCloningModal.tsx';
import { NotificationsProvider } from './__playrepo/ui-components/src/NotificationSnackbar.tsx';

const queryClient = errorsTracker.createReactQueryClient();

export const Providers = () => (
  <SentryFatalErrorBoundary fallback={ErrorPage}>
    <ConfigCatProvider
      sdkKey={CONFIG.configCatKey}
      pollingMode={PollingMode.AutoPoll}
      options={{ pollIntervalSeconds: CONFIG.configCatInterval }}
    >
      <QueryClientProvider client={queryClient}>
        <NotificationsProvider>
          <StripeProvider>
            <AuthProvider>
              <UserProvider>
                <ClarityTacking />
                <Outlet />
                <GlobalModals />
              </UserProvider>
            </AuthProvider>
          </StripeProvider>
        </NotificationsProvider>
      </QueryClientProvider>
    </ConfigCatProvider>
  </SentryFatalErrorBoundary>
);

function GlobalModals() {
  return (
    <>
      <AnnouncementsJournal />
      <PaymentSuccesfulModal />
      <WelcomeTeamMemberModal />
      <InstantVoiceCloningModal />
    </>
  );
}
