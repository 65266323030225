import { SetStateAction, useState } from 'react';
import { LegacyInstantVoiceCloningModal } from './LegacyInstantVoiceCloningModal.tsx';
import { HFVoiceCloningModal } from './HFVoiceCloningModal.tsx';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { VCTypeSelectionModal } from './VCTypeSelectionModal.tsx';
import { VCbuyVoicesModal } from './VCbuyVoicesModal.tsx';
import { useFeatureFlags } from '../../hooks/useFeatureFlags.ts';
import { InstantVoiceCloningModal } from './InstantVoiceCloningModal.tsx';

type ModalType = null | 'choose' | 'upgrade' | 'instant' | 'hf';

// TODO: extract logic to a hook in voiceCloning.hooks.ts
export function CreateVoiceCloneButton({
  instantCloningExceeded,
  hfCloningExceeded,
}: {
  instantCloningExceeded: boolean;
  hfCloningExceeded: boolean;
}) {
  const vcEnhancingEnabled = useFeatureFlags().featureFlags.ENABLE_VOICE_CLONING_ENHANCING;
  const openVoiceCloningModal = InstantVoiceCloningModal.useStore((state) => state.open);
  const [modal, setModal] = useState<ModalType>(null);
  const [voiceCloningType, setVoiceCloningType] = useState<'instant' | 'hf'>('instant');

  const getOpenSetter = (m: ModalType) => (open: SetStateAction<boolean>) => setModal(open ? m : null);

  const chooseVoiceCloningType = (type: 'instant' | 'hf') => {
    setVoiceCloningType(type);
    if (type === 'instant') {
      if (instantCloningExceeded) {
        setModal('upgrade');
      } else {
        if (vcEnhancingEnabled) {
          setModal(null);
          openVoiceCloningModal();
        } else {
          setModal('instant');
        }
      }
    }
    if (type === 'hf') {
      if (hfCloningExceeded) {
        setModal('upgrade');
      } else {
        setModal('hf');
      }
    }
  };

  return (
    <>
      <div
        className="my-6 cursor-pointer rounded-xl bg-neutral-900 p-10 ring-1 ring-inset ring-neutral-50/10 max-tablet:p-6"
        onClick={() => setModal('choose')}
      >
        <div className="flex items-center justify-center gap-4">
          <div className="rounded-xl bg-primary">
            <PlusSmallIcon className="h-12" />
          </div>
          <div className="text-base font-semibold">Create a New Clone</div>
        </div>
      </div>
      <VCTypeSelectionModal
        open={modal === 'choose'}
        setOpen={(open) => setModal(open ? 'choose' : null)}
        onSelectType={chooseVoiceCloningType}
        instantCloningExceeded={instantCloningExceeded}
        hfCloningExceeded={hfCloningExceeded}
      />
      <VCbuyVoicesModal open={modal === 'upgrade'} setOpen={getOpenSetter('upgrade')} type={voiceCloningType} />
      <LegacyInstantVoiceCloningModal open={modal === 'instant'} setOpen={getOpenSetter('instant')} />
      <HFVoiceCloningModal open={modal === 'hf'} setOpen={getOpenSetter('hf')} />
    </>
  );
}
