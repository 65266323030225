import type { ReactNode } from 'react';
import { clsx } from 'clsx';

export function Checkbox({
  name,
  checked,
  disabled,
  label,
  onChange,
}: {
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  label?: ReactNode;
}) {
  return (
    <div className="relative -left-2 inline-flex items-start gap-1">
      <label
        htmlFor={name}
        className="relative flex cursor-pointer items-center rounded-full p-2 top-1 disabled:cursor-not-allowed"
      >
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={(event) => onChange(event.target.checked)}
          className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 bg-gray-700/70 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full !outline-black before:bg-gray-500 before:opacity-0 text-black checked:text-primary-green-600 before:transition-opacity checked:border-primary-green-600 checked:bg-primary-green-600 checked:before:bg-primary-green-600 hover:before:opacity-10 disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-700/70"
        />
        <span
          className={clsx(
            'pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 transition-opacity peer-checked:opacity-100',
            disabled ? 'text-gray-600' : 'text-black'
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </label>
      <label
        htmlFor={name}
        className={clsx('mt-px cursor-pointer select-none py-2', disabled ? 'text-gray-500' : 'text-gray-200')}
      >
        {label}
      </label>
    </div>
  );
}
