import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { clsx } from 'clsx';
import { VoiceGender } from '../../__playrepo/play-voice-domain/src/Voice.ts';
import { Label } from '../../__playrepo/ui-components/src/TextField.tsx';

type Props = {
  value: VoiceGender;
  onToggle: (value: VoiceGender) => void;
};

export function GenderToggle({ value, onToggle }: Props) {
  const options = [
    {
      text: 'Male',
      value: 'male',
      onSelect: () => onToggle('male'),
    },
    {
      text: 'Female',
      value: 'female',
      onSelect: () => onToggle('female'),
    },
  ];

  return (
    <div>
      <Label htmlFor="gender-toggle">Voice Gender</Label>
      <Tab.Group
        id="gender-toggle"
        selectedIndex={options.findIndex(({ value: opt }) => opt === value)}
        as="div"
        className="inline-block rounded-xl text-base font-medium h-10 py-0 ring-1 ring-gray-700 text-gray-200 bg-gray-800"
      >
        <Tab.List>
          {options.map(({ text, onSelect }) => (
            <Tab as={Fragment} key={text}>
              {({ selected }) => (
                <button
                  className={clsx('h-10 px-5 py-2 rounded-xl focus:outline-none w-28', {
                    'bg-primary-green-600 text-black': selected,
                  })}
                  onClick={onSelect}
                >
                  {text}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
